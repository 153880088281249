import { HeroCarousel } from "components/HeroCarousel";
import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import { Photo } from "components/Photo";
import TicketShop from "components/ticket-shop";
import { motion } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet";

const EventServicesPage = () => {
  return (
    <Page>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Rockstar Promotions"}
        meta={[
          {
            name: `description`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:url`,
            content: `https://rockstarpromotions.co.uk`,
          },
          {
            property: `og:title`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: ``,
          },
        ]}
      />
      <section className="mt-16">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row gap-2 md:justify-between mt-16">
            <div className="max-w-xl mb-16 md:mb-64">
              <h1 className="mb-8 md:mt-0">EVENT SERVICES</h1>
              <p>
                Rockstar Promotions have extensive experience in live event
                design and production, creating spectacular and immersive event
                experiences. And we are delighted to now offer our expertiese to
                new clients via our event services portfolio.
              </p>
              <p>
                Whether you're looking for a small sound and lighting solution
                for your event, spectacular entertainment and production for a
                large event, or anything inbetween, we can help! We can supply
                for any aspect of an event, including staging, sound, light,
                decor, special effects, entertainment, live bands, DJ's, event
                hosts, fire performers, themed actors and more!{" "}
              </p>
              <p>
                We can also assist with broadcast and streaming of live or
                recorded content.
              </p>

              <p>
                We bring expertise to each project and to the people that we
                work with. We'd love to talk to you about how we can help with
                your event requirements!
              </p>
            </div>
            <div className="relative flex justify-items-center md:justify-end flex-1">
              <Photo
                style={{ zIndex: 1 }}
                className="relative w-72 transform translate-x-4 md:translate-y-32 md:translate-x-24"
                bgColor="var(--pink)"
                src="/services-2.jpg"
                offsetX={-16}
                offsetY={16}
              >
                <MotionImage
                  className="absolute w-20 bottom-16 -right-12"
                  src={"/icon-lightning-yellow.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.85,
                      delay: 0.95,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
              </Photo>
              <Photo
                className="relative transform w-72 translate-y-2 md:-translate-y-2"
                bgColor="var(--blue)"
                src="/services-1.jpg"
              >
                <MotionImage
                  className="absolute w-20 top-4 -right-10"
                  src={"/icon-note-02.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.75,
                      delay: 0.7,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
              </Photo>
            </div>
          </div>
          <div />
          {/* <div className="flex flex-col md:flex-row-reverse gap-8 md:justify-between mt-32">
            <div className="max-w-xl mb-8" style={{ minWidth: 320 }}></div>
            <div className="relative flex justify-items-center md:justify-start flex-1 mb-32">
              <Photo
                className="relative w-72 transform translate-x-0 translate-y-20"
                bgColor="var(--pink)"
                src="services-4.jpg"
                offsetX={-16}
                offsetY={16}
              >
                <MotionImage
                  className="absolute w-24 -bottom-8 md:bottom-8 md:-right-10"
                  src={"/icon-twinkle-1.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.75,
                      delay: 1,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
              </Photo>
              <Photo
                className="relative transform w-72 -translate-x-16 md:-translate-y-16"
                bgColor="var(--blue)"
                src="services-3.jpg"
              >
                <MotionImage
                  className="absolute w-28 -left-16  bottom-16 md:bottom-28 md:-left-20"
                  src={"/icon-twinkle-2.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.95,
                      delay: 1.2,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.6 }}
                />
              </Photo>
            </div>
          </div> */}
        </div>
      </section>
    </Page>
  );
};

export default EventServicesPage;
